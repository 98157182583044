import React, { useState, useEffect } from 'react';
import arts from './data/arts_and_literature.json'
import entertainment from './data/entertainment.json'
import food from './data/food_and_drink.json'
import geography from './data/geography.json'
import history from './data/history.json'
import language from './data/language.json'
import mathematics from './data/mathematics.json'
import music from './data/music.json'
import people from './data/people_and_places.json'
import religion from './data/religion_and_mythology.json'
import science from './data/science_and_nature.json'
import sports from './data/sport_and_leisure.json'
import tech from './data/tech_an_video_games.json'
import toys from './data/toys_and_games.json'

import 'bootstrap/dist/css/bootstrap.css';

import './App.css';

interface Category {
  name: string
  questions: Question[]
  count: number
}

interface Question {
  category_id: string
  lang: string, 
  tags:string[], 
  question: string, 
  answer: number
  answers: string[]
  source: string
}

function App() {
  const [categories, setCategories] = useState<Category[]>([
    {
      name: "ART_AND_LITERATURE",
      questions: arts,
      count: 0
    },
    {
      name: "ENTERTAINMENT",
      questions: entertainment,
      count: 0
    },
    {
      name: "FOOD_AND_DRINKS",
      questions: food,
      count: 0
    },
    {
      name: "GEOGRAPHY",
      questions: geography,
      count: 0
    },
    {
      name: "HISTORY",
      questions: history,
      count: 0
    },
    {
      name: "LANGUAGE",
      questions: language,
      count: 0
    },
    {
      name: "MATHEMATICS",
      questions: mathematics,
      count: 0
    },
    {
      name: "MUSIC",
      questions: music,
      count: 0
    },
    {
      name: "PEOPLE_AND_PLACES",
      questions: people,
      count: 0
    },
    {
      name: "RELIGION_AND_MYTHOLOGY",
      questions: religion,
      count: 0
    },
    {
      name: "SCIENCE_AND_NATURE",
      questions: science,
      count: 0
    },
    {
      name: "SPORT_AND_LEISURE",
      questions: sports,
      count: 0
    },
    {
      name: "TECH_AND_VIDEO_GAMES",
      questions: tech,
      count: 0
    },
    {
      name: "TOYS_AND_GAMES",
      questions: toys,
      count: 0
    }
  ])
  const [questionCount, setQuestionCount] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(-1)
  const [revealed, setRevealed] = useState(false)
  const [questions, setQuestions] = useState<Question[]>([])
  

  useEffect(() => {
    let sum = 0
    for (let category of categories) {
      sum += category.count
    }
    setQuestionCount(sum)
  }, [categories])
  
  const setCategoryCount = (index: number, value: string) => {
    const number = parseInt(value)
    if (isNaN(number)) {
      return
    }
    if (number < 0) {
      return
    }
    const c = [...categories]
    c[index].count = number
    setCategories(c)
  }
  const shuffle = <T extends {} >(array: T[]): T[] => {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }
  
  const fetchQuestions = async () => {
    let questions: Question[] = []
    for (let category of categories) {
      let qs = category.questions.filter(question => question.answers.length === 1)
      for (let i = 0; i < category.count; i++) {
        const index = Math.floor(Math.random() * Math.floor(qs.length))
        const q = qs[index]
        questions.push(q)
        console.log(qs[index].question)
        qs = qs.filter(question => question !== q)
      }
    }
    questions = shuffle(questions)
    setQuestions(questions)
    setCurrentQuestion(0)
    return false
  }
  
  const questionAction = () => {
    if (!revealed) {
      setRevealed(true)
      return
    }
    if (currentQuestion === questions.length - 1) {
      setRevealed(false)
      setCurrentQuestion(-1)
      setQuestions([])
      return
    }
    setRevealed(false)
    setCurrentQuestion(currentQuestion + 1)
  }
  
  const replaceQuestion = () => {
    const question = questions[currentQuestion]
    const category = categories.filter(c => c.name === question.category_id)[0]
    const index = Math.floor(Math.random() * Math.floor(category.questions.length))
    const qs = [...questions]
    qs[currentQuestion] = category.questions[index]
    setRevealed(false)
    setQuestions(qs)
  }
  
  return (
    <div className="App col">
      {currentQuestion === -1 &&
        <form className="row category-list" onSubmit={(e) => {e.preventDefault(); fetchQuestions()}}>
          {categories.map((category, index) => {
            const key = `category-form-${category.name}`
            return (
              <div key={key} className="form-group col-2">
                <h2 className="category-name">{category.name.replaceAll("_", " ").toLowerCase()}</h2>
                <div className="form-inline">
                  <label htmlFor={key + "-easy"}>Questions:</label>
                  <input type="number" size={2} className="form-control question-count" id={key + "-easy"} onChange={(e) => setCategoryCount(index, e.target.value)} value={category.count} />
                </div>
              </div>
            )
          })}
          <div className="col-12 submit-form-thingus">
            <input type="submit" className="btn btn-success" value={`Do the thing (${questionCount} questions)`} disabled={questionCount === 0} />
          </div>
        </form>
      }
      {currentQuestion > -1 &&
        <div className={revealed ? "revealed question" : "question"}>
          <div className="question-header">
            <div>
              <h4>{questions[currentQuestion].category_id.replaceAll("_", " ").toLowerCase()}</h4>
            </div>
            <span>{currentQuestion + 1} of {questions.length}</span>
          </div>
          <div className="question-body">
            <div className="question-text">{unescape(questions[currentQuestion].question)}</div>
            <div className="answer">
              {questions[currentQuestion].answers[0]}
            </div>
          </div>
          <div className="question-footer">
            <button className="btn btn-warning" onClick={replaceQuestion}>Dumb Question</button>
            <button className="btn btn-info" onClick={questionAction}>{revealed ? (currentQuestion === questions.length - 1 ? "Play Again" : "Next Question") : "Reveal Answer"}</button>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
